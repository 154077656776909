@import "variables";
@import "mixin";

//
// Search
//

.go-event {
  border-radius: $border-rounded;
  overflow: hidden;
  position: relative;
  background-color: $color-contrast;
  border: $border-width solid rgba($color-border, .2);
  @include transitionAll;
  &:hover {
    @include box-shadow(10px); }
  &.uk-open {
    @include box-shadow(10px);
    .go-event-content-open {
      transform: rotate(180deg); } } }
.go-event-content-open {
  @include transition(transform); }
.go-event--title {
  line-height: 1.2;
  font-size: 1.2rem; }
.go-event--meta {
  color: $color-muted;
  font-size: .9rem; }
.go-event-toggle {
  cursor: pointer; }
.go-event-actions {
  border-top: $border-width solid rgba($color-border, .3);
  padding: $global-margin; }
.go-event-content {
  padding: $global-margin;
  background-color: $color-bgr-muted;
  & > * {
    &:last-child {
      margin-bottom: 0; } } }
.go-event-price {
  & > span {
    color: $color-primary;
    font-size: 1.2rem; } }
.go-event-spiker {
  background-color: $color-primary;
  color: $color-contrast;
  padding: $global-margin; }

.uk-input {
  &.error {
    border-color: rgba($color-danger, .3);
    color: $color-danger;
    &:focus {
      background-color: rgba($color-danger, .1); } } }
