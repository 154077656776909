@import "variables";
@import "mixin";

//
// Header
//

.go-header {}

// go-header-top
.go-header-top {
  padding: $global-margin;
  border-bottom: $border-width solid $color-border;
  font-size: $global-small-font-size;
  @media (max-width: $breakpoint-medium) {
    padding: $global-margin/2 $global-margin; } }
.go-header-top--logo {
  @include transition(transform);
  width: 103px;
  height: 111px;
  @media (max-width: $breakpoint-medium) {
    width: 80px;
    height: 86px; }
  @media (max-width: $breakpoint-small) {
    width: 40px;
    height: 43px; }
  &:hover {
    transform: scale(1.01); } }
.go-header-logo-title--main {
  line-height: 14px; }
.go-header-logo-title--heading {
  font-size: 42px;
  line-height: 55px;
  @media (max-width: $breakpoint-medium) {
    font-size: 28px;
    line-height: 1; } }
.go-header-logo-title--meta {
  color: $color-muted;
  line-height: 14px; }
.go-header-contacts {
  list-style: none;
  margin: 0;
  padding: 0;
  & > li {
    white-space: nowrap;
    line-height: 22px; } }

// go-header-nav
.go-header-nav {
  height: 70px;
  text-align: center;
  background-color: $color-contrast;
  @media (max-width: $breakpoint-medium) {
    height: 50px;
    .uk-navbar-toggle,
    .uk-navbar-item {
      height: 50px; } }
  //border-bottom: $border-width solid $color-border
  &.uk-active {
    @include box-shadow(20px); }
  .uk-search-input {
    text-align: center; } }
.accessibility-view {
  .uk-navbar-nav > li > a,
  .uk-navbar-item,
  .uk-navbar-toggle {
    font-size: $global-font-size-accessibility-on; } }

// go-header-slider
.go-header-slider {
  position: relative;
  margin: 0 -1px;
  height: $header-slider-height;
  background-color: $color-bgr-muted;
  @media (max-width: $breakpoint-small) {
    height: 200px; } }

.go-header-slider-items,
.uk-slideshow-items {
  height: $header-slider-height;
  @media (max-width: $breakpoint-small) {
    height: 200px; } }

.go-header-slider--item {
  overflow: hidden;
  height: $header-slider-height;
  background: 50% 50% no-repeat;
  background-size: 1400px 290px;
  @media (max-width: $breakpoint-small) {
    background-size: 965px 200px;
    height: 200px; } }

.go-header-slider--dots {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%); }
