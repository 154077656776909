//
// BASE
//

@import 'variables';
@import "mixin";

html {
  min-width: 320px; }

// PT+Sans:400,400i,700
html,
body {
  font-weight: 400;
  font-family: 'PT Sans', sans-serif;
  min-height: 100vh; }
body {
  font-size: $global-font-size-accessibility-off;
  line-height: $global-line-height;
  background-color: $color-bgr-muted;
  &.accessibility-view {
    font-size: $global-font-size-accessibility-on; } }

// main container
.go-container {
  max-width: $breakpoint-xlarge;
  min-height: 100vh;
  border-left: $border-width solid $color-border;
  border-right: $border-width solid $color-border;
  background-color: $color-contrast;
  margin: 0 auto;
  overflow-x: hidden;
  overflow-y: auto; }

// отключение блоков при вкл. версии для слабовидящих
.accessibility-view {
  .accessibility-view-hide {
    display: none !important; } }
// ui-kit
.uk-button-sq {
  width: 40px;
  border-radius: $border-rounded;
  padding: 0; }
.uk-navbar-item {
  &:hover {
    text-decoration: none; }
  & > .uk-icon {
    & + * {
      margin-left: 5px; } } }
.autosize {
  @include transitionTime(1s);
  min-height: $global-control-height;
  max-height: $global-control-height * 4;
  &.uk-form-large {
    min-height: $global-control-large-height;
    max-height: $global-control-large-height * 4; }
  &.uk-form-sma {
    min-height: $global-control-large-height;
    max-height: $global-control-large-height * 4; } }

/* borders */
.go-border {
  border: $border-width solid $color-border; }
.go-border-top {
  border-top: $border-width solid $color-border; }
.go-border-bottomp {
  border-bottom: $border-width solid $color-border; }
.go-border-left {
  border-left: $border-width solid $color-border; }
.go-border-right {
  border-right: $border-width solid $color-border; }

.uk-leader-fill {
  display: block; }
