/* required styles */

.leaflet-pane,
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-tile-container,
.leaflet-pane > svg,
.leaflet-pane > canvas,
.leaflet-zoom-box,
.leaflet-image-layer,
.leaflet-layer {
  position: absolute;
  left: 0;
  top: 0;
}

.leaflet-container {
  overflow: hidden;
}

.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

/* Prevents IE11 from highlighting tiles in blue */

.leaflet-tile::selection {
  background: transparent;
}

/* Safari renders non-retina tile on retina better with this, but Chrome is worse */

.leaflet-safari .leaflet-tile {
  image-rendering: -webkit-optimize-contrast;
}

/* hack that prevents hw layers "stretching" when loading new tiles */

.leaflet-safari .leaflet-tile-container {
  width: 1600px;
  height: 1600px;
  -webkit-transform-origin: 0 0;
}

.leaflet-marker-icon,
.leaflet-marker-shadow {
  display: block;
}

/* .leaflet-container svg: reset svg max-width decleration shipped in Joomla! (joomla.org) 3.x */

/* .leaflet-container img: map is broken in FF if you have max-width: 100% on tiles */

.leaflet-container .leaflet-overlay-pane svg,
.leaflet-container .leaflet-marker-pane img,
.leaflet-container .leaflet-shadow-pane img,
.leaflet-container .leaflet-tile-pane img,
.leaflet-container img.leaflet-image-layer,
.leaflet-container .leaflet-tile {
  max-width: none !important;
  max-height: none !important;
}

.leaflet-container.leaflet-touch-zoom {
  -ms-touch-action: pan-x pan-y;
  touch-action: pan-x pan-y;
}

.leaflet-container.leaflet-touch-drag {
  -ms-touch-action: pinch-zoom;
  /* Fallback for FF which doesn't support pinch-zoom */
  touch-action: none;
  touch-action: pinch-zoom;
}

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  -ms-touch-action: none;
  touch-action: none;
}

.leaflet-container {
  -webkit-tap-highlight-color: transparent;
}

.leaflet-container a {
  -webkit-tap-highlight-color: rgba(51, 181, 229, 0.4);
}

.leaflet-tile {
  filter: inherit;
  visibility: hidden;
}

.leaflet-tile-loaded {
  visibility: inherit;
}

.leaflet-zoom-box {
  width: 0;
  height: 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 800;
}

/* workaround for https://bugzilla.mozilla.org/show_bug.cgi?id=888319 */

.leaflet-overlay-pane svg {
  -moz-user-select: none;
}

.leaflet-pane {
  z-index: 400;
}

.leaflet-tile-pane {
  z-index: 200;
}

.leaflet-overlay-pane {
  z-index: 400;
}

.leaflet-shadow-pane {
  z-index: 500;
}

.leaflet-marker-pane {
  z-index: 600;
}

.leaflet-tooltip-pane {
  z-index: 650;
}

.leaflet-popup-pane {
  z-index: 700;
}

.leaflet-map-pane canvas {
  z-index: 100;
}

.leaflet-map-pane svg {
  z-index: 200;
}

.leaflet-vml-shape {
  width: 1px;
  height: 1px;
}

.lvml {
  behavior: url("../node_modules/leaflet/dist/#default#VML");
  display: inline-block;
  position: absolute;
}

/* control positioning */

.leaflet-control {
  position: relative;
  z-index: 800;
  pointer-events: visiblePainted;
  /* IE 9-10 doesn't have auto */
  pointer-events: auto;
}

.leaflet-top,
.leaflet-bottom {
  position: absolute;
  z-index: 1000;
  pointer-events: none;
}

.leaflet-top {
  top: 0;
}

.leaflet-right {
  right: 0;
}

.leaflet-bottom {
  bottom: 0;
}

.leaflet-left {
  left: 0;
}

.leaflet-control {
  float: left;
  clear: both;
}

.leaflet-right .leaflet-control {
  float: right;
}

.leaflet-top .leaflet-control {
  margin-top: 10px;
}

.leaflet-bottom .leaflet-control {
  margin-bottom: 10px;
}

.leaflet-left .leaflet-control {
  margin-left: 10px;
}

.leaflet-right .leaflet-control {
  margin-right: 10px;
}

/* zoom and fade animations */

.leaflet-fade-anim .leaflet-tile {
  will-change: opacity;
}

.leaflet-fade-anim .leaflet-popup {
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  opacity: 1;
}

.leaflet-zoom-animated {
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
  will-change: transform;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0,0,0.25,1);
  -moz-transition: -moz-transform 0.25s cubic-bezier(0,0,0.25,1);
  transition: transform 0.25s cubic-bezier(0,0,0.25,1);
}

.leaflet-zoom-anim .leaflet-tile,
.leaflet-pan-anim .leaflet-tile {
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
}

.leaflet-zoom-anim .leaflet-zoom-hide {
  visibility: hidden;
}

/* cursors */

.leaflet-interactive {
  cursor: pointer;
}

.leaflet-grab {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.leaflet-crosshair,
.leaflet-crosshair .leaflet-interactive {
  cursor: crosshair;
}

.leaflet-popup-pane,
.leaflet-control {
  cursor: auto;
}

.leaflet-dragging .leaflet-grab,
.leaflet-dragging .leaflet-grab .leaflet-interactive,
.leaflet-dragging .leaflet-marker-draggable {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

/* marker & overlays interactivity */

.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-image-layer,
.leaflet-pane > svg path,
.leaflet-tile-container {
  pointer-events: none;
}

.leaflet-marker-icon.leaflet-interactive,
.leaflet-image-layer.leaflet-interactive,
.leaflet-pane > svg path.leaflet-interactive,
svg.leaflet-image-layer.leaflet-interactive path {
  pointer-events: visiblePainted;
  /* IE 9-10 doesn't have auto */
  pointer-events: auto;
}

/* visual tweaks */

.leaflet-container {
  background: #ddd;
  outline: 0;
}

.leaflet-container a {
  color: #0078A8;
}

.leaflet-container a.leaflet-active {
  outline: 2px solid orange;
}

.leaflet-zoom-box {
  border: 2px dotted #38f;
  background: rgba(255,255,255,0.5);
}

/* general typography */

.leaflet-container {
  font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
}

/* general toolbar styles */

.leaflet-bar {
  box-shadow: 0 1px 5px rgba(0,0,0,0.65);
  border-radius: 4px;
}

.leaflet-bar a,
.leaflet-bar a:hover {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  display: block;
  text-align: center;
  text-decoration: none;
  color: black;
}

.leaflet-bar a,
.leaflet-control-layers-toggle {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: block;
}

.leaflet-bar a:hover {
  background-color: #f4f4f4;
}

.leaflet-bar a:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.leaflet-bar a:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom: none;
}

.leaflet-bar a.leaflet-disabled {
  cursor: default;
  background-color: #f4f4f4;
  color: #bbb;
}

.leaflet-touch .leaflet-bar a {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

/* zoom control */

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  font: bold 18px 'Lucida Console', Monaco, monospace;
  text-indent: 1px;
}

.leaflet-touch .leaflet-control-zoom-in,
.leaflet-touch .leaflet-control-zoom-out {
  font-size: 22px;
}

/* layers control */

.leaflet-control-layers {
  box-shadow: 0 1px 5px rgba(0,0,0,0.4);
  background: #fff;
  border-radius: 5px;
}

.leaflet-control-layers-toggle {
  background-image: url("../node_modules/leaflet/dist/images/layers.png");
  width: 36px;
  height: 36px;
}

.leaflet-retina .leaflet-control-layers-toggle {
  background-image: url("../node_modules/leaflet/dist/images/layers-2x.png");
  background-size: 26px 26px;
}

.leaflet-touch .leaflet-control-layers-toggle {
  width: 44px;
  height: 44px;
}

.leaflet-control-layers .leaflet-control-layers-list,
.leaflet-control-layers-expanded .leaflet-control-layers-toggle {
  display: none;
}

.leaflet-control-layers-expanded .leaflet-control-layers-list {
  display: block;
  position: relative;
}

.leaflet-control-layers-expanded {
  padding: 6px 10px 6px 6px;
  color: #333;
  background: #fff;
}

.leaflet-control-layers-scrollbar {
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 5px;
}

.leaflet-control-layers-selector {
  margin-top: 2px;
  position: relative;
  top: 1px;
}

.leaflet-control-layers label {
  display: block;
}

.leaflet-control-layers-separator {
  height: 0;
  border-top: 1px solid #ddd;
  margin: 5px -10px 5px -6px;
}

/* Default icon URLs */

.leaflet-default-icon-path {
  background-image: url("../node_modules/leaflet/dist/images/marker-icon.png");
}

/* attribution and scale controls */

.leaflet-container .leaflet-control-attribution {
  background: #fff;
  background: rgba(255, 255, 255, 0.7);
  margin: 0;
}

.leaflet-control-attribution,
.leaflet-control-scale-line {
  padding: 0 5px;
  color: #333;
}

.leaflet-control-attribution a {
  text-decoration: none;
}

.leaflet-control-attribution a:hover {
  text-decoration: underline;
}

.leaflet-container .leaflet-control-attribution,
.leaflet-container .leaflet-control-scale {
  font-size: 11px;
}

.leaflet-left .leaflet-control-scale {
  margin-left: 5px;
}

.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 5px;
}

.leaflet-control-scale-line {
  border: 2px solid #777;
  border-top: none;
  line-height: 1.1;
  padding: 2px 5px 1px;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  background: rgba(255, 255, 255, 0.5);
}

.leaflet-control-scale-line:not(:first-child) {
  border-top: 2px solid #777;
  border-bottom: none;
  margin-top: -2px;
}

.leaflet-control-scale-line:not(:first-child):not(:last-child) {
  border-bottom: 2px solid #777;
}

.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  box-shadow: none;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: 2px solid rgba(0,0,0,0.2);
  background-clip: padding-box;
}

/* popup */

.leaflet-popup {
  position: absolute;
  text-align: center;
  margin-bottom: 20px;
}

.leaflet-popup-content-wrapper {
  padding: 1px;
  text-align: left;
  border-radius: 12px;
}

.leaflet-popup-content {
  margin: 13px 19px;
  line-height: 1.4;
}

.leaflet-popup-content p {
  margin: 18px 0;
}

.leaflet-popup-tip-container {
  width: 40px;
  height: 20px;
  position: absolute;
  left: 50%;
  margin-left: -20px;
  overflow: hidden;
  pointer-events: none;
}

.leaflet-popup-tip {
  width: 17px;
  height: 17px;
  padding: 1px;
  margin: -10px auto 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: white;
  color: #333;
  box-shadow: 0 3px 14px rgba(0,0,0,0.4);
}

.leaflet-container a.leaflet-popup-close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 4px 0 0;
  border: none;
  text-align: center;
  width: 18px;
  height: 14px;
  font: 16px/14px Tahoma, Verdana, sans-serif;
  color: #c3c3c3;
  text-decoration: none;
  font-weight: bold;
  background: transparent;
}

.leaflet-container a.leaflet-popup-close-button:hover {
  color: #999;
}

.leaflet-popup-scrolled {
  overflow: auto;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
}

.leaflet-oldie .leaflet-popup-content-wrapper {
  zoom: 1;
}

.leaflet-oldie .leaflet-popup-tip {
  width: 24px;
  margin: 0 auto;
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
  filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678);
}

.leaflet-oldie .leaflet-popup-tip-container {
  margin-top: -1px;
}

.leaflet-oldie .leaflet-control-zoom,
.leaflet-oldie .leaflet-control-layers,
.leaflet-oldie .leaflet-popup-content-wrapper,
.leaflet-oldie .leaflet-popup-tip {
  border: 1px solid #999;
}

/* div icon */

.leaflet-div-icon {
  background: #fff;
  border: 1px solid #666;
}

/* Tooltip */

/* Base styles for the element that has a tooltip */

.leaflet-tooltip {
  position: absolute;
  padding: 6px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  color: #222;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  box-shadow: 0 1px 3px rgba(0,0,0,0.4);
}

.leaflet-tooltip.leaflet-clickable {
  cursor: pointer;
  pointer-events: auto;
}

.leaflet-tooltip-top:before,
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  position: absolute;
  pointer-events: none;
  border: 6px solid transparent;
  background: transparent;
  content: "";
}

/* Directions */

.leaflet-tooltip-bottom {
  margin-top: 6px;
}

.leaflet-tooltip-top {
  margin-top: -6px;
}

.leaflet-tooltip-bottom:before,
.leaflet-tooltip-top:before {
  left: 50%;
  margin-left: -6px;
}

.leaflet-tooltip-top:before {
  bottom: 0;
  margin-bottom: -12px;
  border-top-color: #fff;
}

.leaflet-tooltip-bottom:before {
  top: 0;
  margin-top: -12px;
  margin-left: -6px;
  border-bottom-color: #fff;
}

.leaflet-tooltip-left {
  margin-left: -6px;
}

.leaflet-tooltip-right {
  margin-left: 6px;
}

.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  top: 50%;
  margin-top: -6px;
}

.leaflet-tooltip-left:before {
  right: 0;
  margin-right: -12px;
  border-left-color: #fff;
}

.leaflet-tooltip-right:before {
  left: 0;
  margin-left: -12px;
  border-right-color: #fff;
}

html {
  min-width: 320px;
}

html,
body {
  font-weight: 400;
  font-family: 'PT Sans', sans-serif;
  min-height: 100vh;
}

body {
  font-size: 16px;
  line-height: 1.25;
  background-color: #f6f6f6;
}

body.accessibility-view {
  font-size: 20px;
}

.go-container {
  max-width: 1400px;
  min-height: 100vh;
  border-left: 1px solid rgba(151, 151, 151, 0.4);
  border-right: 1px solid rgba(151, 151, 151, 0.4);
  background-color: #ffffff;
  margin: 0 auto;
  overflow-x: hidden;
  overflow-y: auto;
}

.accessibility-view .accessibility-view-hide {
  display: none !important;
}

.uk-button-sq {
  width: 40px;
  border-radius: 5px;
  padding: 0;
}

.uk-navbar-item:hover {
  text-decoration: none;
}

.uk-navbar-item > .uk-icon + * {
  margin-left: 5px;
}

.autosize {
  transition: 1s ease;
  min-height: 40px;
  max-height: 160px;
}

.autosize.uk-form-large {
  min-height: 55px;
  max-height: 220px;
}

.autosize.uk-form-sma {
  min-height: 55px;
  max-height: 220px;
}

/* borders */

.go-border {
  border: 1px solid rgba(151, 151, 151, 0.4);
}

.go-border-top {
  border-top: 1px solid rgba(151, 151, 151, 0.4);
}

.go-border-bottomp {
  border-bottom: 1px solid rgba(151, 151, 151, 0.4);
}

.go-border-left {
  border-left: 1px solid rgba(151, 151, 151, 0.4);
}

.go-border-right {
  border-right: 1px solid rgba(151, 151, 151, 0.4);
}

.uk-leader-fill {
  display: block;
}

.go-header-top {
  padding: 20px;
  border-bottom: 1px solid rgba(151, 151, 151, 0.4);
  font-size: 0.75rem;
}

@media (max-width: 768px) {
  .go-header-top {
    padding: 10px 20px;
  }
}

.go-header-top--logo {
  transition: transform 0.3s ease;
  width: 103px;
  height: 111px;
}

@media (max-width: 768px) {
  .go-header-top--logo {
    width: 80px;
    height: 86px;
  }
}

@media (max-width: 480px) {
  .go-header-top--logo {
    width: 40px;
    height: 43px;
  }
}

.go-header-top--logo:hover {
  transform: scale(1.01);
}

.go-header-logo-title--main {
  line-height: 14px;
}

.go-header-logo-title--heading {
  font-size: 42px;
  line-height: 55px;
}

@media (max-width: 768px) {
  .go-header-logo-title--heading {
    font-size: 28px;
    line-height: 1;
  }
}

.go-header-logo-title--meta {
  color: #9b9b9b;
  line-height: 14px;
}

.go-header-contacts {
  list-style: none;
  margin: 0;
  padding: 0;
}

.go-header-contacts > li {
  white-space: nowrap;
  line-height: 22px;
}

.go-header-nav {
  height: 70px;
  text-align: center;
  background-color: #ffffff;
}

@media (max-width: 768px) {
  .go-header-nav {
    height: 50px;
  }

  .go-header-nav .uk-navbar-toggle,
  .go-header-nav .uk-navbar-item {
    height: 50px;
  }
}

.go-header-nav.uk-active {
  box-shadow: 0 2px 20px rgba(40, 40, 40, 0.1);
}

.go-header-nav .uk-search-input {
  text-align: center;
}

.accessibility-view .uk-navbar-nav > li > a,
.accessibility-view .uk-navbar-item,
.accessibility-view .uk-navbar-toggle {
  font-size: 20px;
}

.go-header-slider {
  position: relative;
  margin: 0 -1px;
  height: 290px;
  background-color: #f6f6f6;
}

@media (max-width: 480px) {
  .go-header-slider {
    height: 200px;
  }
}

.go-header-slider-items,
.uk-slideshow-items {
  height: 290px;
}

@media (max-width: 480px) {
  .go-header-slider-items,
  .uk-slideshow-items {
    height: 200px;
  }
}

.go-header-slider--item {
  overflow: hidden;
  height: 290px;
  background: 50% 50% no-repeat;
  background-size: 1400px 290px;
}

@media (max-width: 480px) {
  .go-header-slider--item {
    background-size: 965px 200px;
    height: 200px;
  }
}

.go-header-slider--dots {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.go-news-list-item {
  display: block;
  color: #282828;
  padding: 20px;
  border-radius: 5px;
  transition: .3s ease;
}

.go-news-list-item:hover {
  background-color: #ffffff;
  text-decoration: none;
  color: #282828;
  box-shadow: 0 2px 19px rgba(40, 40, 40, 0.1);
  transform: translateY(-2px);
}

.go-news-list-item:hover .go-news-list-item--title {
  color: #0076ff;
}

.go-news-list-item--title {
  font-weight: 700;
  margin-bottom: 8px;
}

.go-news-list-item--meta {
  font-size: 12px;
  color: #9b9b9b;
  font-style: italic;
}

.go-news-list-item--image {
  margin-top: 5px;
  width: 107px;
  background: 50% 50% no-repeat;
  background-size: cover;
  border-radius: 5px;
}

.go-news-filter--link {
  color: #9b9b9b;
}

.go-news-filter > li:nth-child(1) {
  padding-left: 0;
}

.go-news-filter input:checked + .go-news-filter--link {
  color: #0076ff;
}

.go-news-filter .go-news-filter--link:hover,
.go-news-filter .go-news-filter--link:focus {
  color: #282828;
  text-decoration: none;
  outline: 0;
}

.go-footer-banners {
  height: 225px;
  background-color: #f6f6f6;
  border-top: 1px solid rgba(151, 151, 151, 0.4);
  border-bottom: 1px solid rgba(151, 151, 151, 0.4);
}

.go-footer-subscribe {
  padding: 20px;
  border-top: 1px solid rgba(151, 151, 151, 0.4);
}

.go-footer-subscribe > *:first-child {
  max-width: 400px;
  margin: 0 auto;
}

.go-footer-copyright {
  box-sizing: border-box;
  padding: 0 25px;
  height: 70px;
  line-height: 70px;
  text-align: center;
}

@media (max-width: 768px) {
  .go-footer-copyright {
    height: 40px;
    line-height: 40px;
  }
}

.go-content {
  border-top: 1px solid rgba(151, 151, 151, 0.4);
}

.go-content-main {
  padding: 35px 20px;
}

@media (max-width: 768px) {
  .go-content-main {
    padding: 20px 10px;
  }
}

.go-content-side {
  border-left: 1px solid rgba(151, 151, 151, 0.4);
  text-align: center;
  padding: 35px 20px;
  width: 288px;
  height: 100%;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .go-content-side {
    padding: 20px 10px;
  }
}

@media (max-width: 960px) {
  .go-content-side {
    width: auto;
  }
}

.go-content-side-nav {
  position: relative;
  display: block;
  height: 120px;
  border: 1px solid #0076ff;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .go-content-side-nav {
    height: 90px;
  }
}

@media (max-width: 480px) {
  .go-content-side-nav {
    height: auto;
  }
}

.go-content-side-nav:hover {
  border-color: #005cbe;
}

.go-content-side-nav:hover .go-content-side-nav--title {
  color: #0076ff;
  text-decoration: none;
}

.go-content-side-nav:hover > .go-content-side-nav--icon {
  transform: scale(0.96);
}

@media (max-width: 768px) {
  .go-content-side-nav:hover > .go-content-side-nav--icon {
    transform: scale(0.85);
  }
}

.go-content-side-nav--icon {
  width: 65px;
  height: 50px;
  margin: 10px auto 0 auto;
  background: 50% 50% no-repeat;
  background-size: 45px 35px;
  transition: transform 0.3s ease;
}

@media (max-width: 768px) {
  .go-content-side-nav--icon {
    transform: scale(0.8);
    margin: 0 auto;
  }
}

@media (max-width: 480px) {
  .go-content-side-nav--icon {
    display: none;
  }
}

.go-content-side-nav--title {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  color: #282828;
  font-size: 1rem;
  padding: 0 10px;
  line-height: 1.3;
  height: 50px;
}

@media (max-width: 768px) {
  .go-content-side-nav--title {
    font-size: .875rem;
    line-height: 1;
    height: auto;
  }
}

@media (max-width: 480px) {
  .go-content-side-nav--title {
    position: static;
    padding: 10px;
  }
}

.go-content-side-e-reception {
  height: 155px;
  box-sizing: border-box;
  padding: 12px 0;
  display: block;
  background-color: #0076ff;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

@media (max-width: 960px) {
  .go-content-side-e-reception {
    height: 90px;
  }
}

.go-content-side-e-reception > .go-content-side-nav--icon {
  height: 130px;
  width: 150px;
  margin: 0;
  display: inline-block;
  background-size: 100% auto;
}

@media (max-width: 960px) {
  .go-content-side-e-reception > .go-content-side-nav--icon {
    transform: scale(0.5) translateY(-50px);
  }
}

.go-content-side-e-reception:hover {
  background-color: #005cbe;
}

@media (max-width: 960px) {
  .go-content-side-e-reception:hover > .go-content-side-nav--icon {
    transform: scale(0.51) translateY(-50px);
  }
}

.go-subscribe input {
  border-radius: 5px 0 0 5px;
}

.go-subscribe button {
  border-radius: 0 5px 5px 0;
  padding: 0;
  width: 40px;
}

.go-service-item {
  display: block;
  height: 85px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #0076ff;
  overflow: hidden;
  color: #282828;
  transition: .3s ease;
}

@media (max-width: 768px) {
  .go-service-item {
    height: 55px;
    font-size: .875rem;
  }
}

.go-service-item.uk-active,
.go-service-item:hover {
  background-color: #0076ff;
  color: #ffffff;
  text-decoration: none;
  transform: scale(1.02);
}

.go-service-item.uk-active {
  transform: none;
}

.go-service-item--icon {
  width: 77px;
  height: 83px;
  background: #0076ff 50% 50% no-repeat;
  background-size: 45px 45px;
  text-align: center;
  padding-top: 7px;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .go-service-item--icon {
    width: 40px;
    height: 53px;
    padding: 0;
  }
}

@media (max-width: 768px) {
  .go-service-item--icon > * {
    transform: scale(0.5);
  }
}

.go-service-item--icon.no-icon {
  width: 20px;
}

.go-service-item--title {
  padding: 0 20px;
  box-sizing: border-box;
  line-height: 18px;
  font-size: 14px;
}

@media (max-width: 768px) {
  .go-service-item--title {
    font-size: 12px;
    line-height: 1.2;
    padding: 0 10px;
  }
}

.go-partner {
  text-align: center;
  position: relative;
}

.go-partner--image {
  height: 100px;
  vertical-align: center;
}

.go-partner--image > img {
  filter: grayscale(1);
  opacity: .3;
  transition: 0.3s ease;
}

.go-partner--caption {
  color: #9b9b9b;
  padding-top: 20px;
  max-width: 180px;
  margin: 0 auto;
  transition: 0.5s ease;
}

.go-partner:hover .go-partner--image > img {
  filter: grayscale(0);
  opacity: 1;
}

.go-partner:hover .go-partner--caption {
  color: #282828;
  text-decoration: none;
  transform: translateY(-10px);
}

.go-banner {
  display: block;
}

.go-gallery {
  margin-top: 40px;
}

.go-gallery-item {
  position: relative;
  overflow: hidden;
}

.go-gallery-item:hover .go-gallery-item--image {
  transform: scale(1.1);
}

.go-gallery-item:hover .go-gallery-item--overlay {
  background-color: rgba(255, 255, 255, 0.4);
}

.go-gallery-item:hover .go-gallery-item--overlay > a {
  color: #282828;
}

.go-gallery-item--image {
  transition: 1s ease;
}

.go-gallery-item--overlay {
  z-index: 1;
  transition: .3s ease;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0);
}

.go-gallery-item--overlay > a {
  color: #ffffff;
}

.go-files {
  margin-top: 70px;
  border-radius: 5px;
}

.go-files-title {
  background-color: #f6f6f6;
  padding: 20px;
  font-size: 1.2rem;
  font-weight: bold;
}

.go-file-item {
  box-sizing: border-box;
  color: #0076ff;
}

.go-file-item:hover {
  text-decoration: none;
  color: #005cbe;
  box-shadow: 0 2px 20px rgba(40, 40, 40, 0.1);
}

.go-file-item:hover .go-file-item__download {
  visibility: visible;
}

.go-file-item__download {
  visibility: hidden;
  padding: 0 10px;
  color: #cc180a;
  animation: download 1s linear infinite;
}

.go-file-item__title {
  display: block;
  line-height: 1.3;
  box-sizing: border-box;
  padding: 10px 0;
}

.go-file-item__icon {
  padding: 10px 0 10px 10px;
  width: 40px;
  height: 40px;
  box-sizing: content-box;
  background: 50% 50% no-repeat;
  display: block;
  background-size: 40px 40px;
}

.go-pdf {
  background-image: url(../images/icons/files-icon/pdf.svg);
}

.go-doc,
.go-docx {
  background-image: url(../images/icons/files-icon/doc.svg);
}

.go-xls,
.go-xlsx {
  background-image: url(../images/icons/files-icon/xls.svg);
}

@keyframes download {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }

  50% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    transform: translateY(10px);
    opacity: 0;
  }
}

.go-content-childrens {
  margin-bottom: 20px;
}

.go-content-childrens > .uk-active > .go-content-children,
.go-content-children:hover {
  background-color: #0076ff;
  border: 1px solid rgba(151, 151, 151, 0.4);
  color: #ffffff;
}

.go-content-children {
  border-radius: 5px;
  position: relative;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #0076ff;
  color: #0076ff;
  height: 100%;
  box-sizing: border-box;
}

.go-content-children--title {
  font-size: 1.2rem;
  margin-bottom: 0;
}

.go-contacts .go-contacts--row {
  margin-top: 5px;
}

.go-contacts--map {
  height: 600px;
}

@media (max-width: 768px) {
  .go-contacts--map {
    height: 400px;
  }
}

@media (max-width: 768px) {
  .media-tab {
    overflow: auto;
    max-height: 200px;
    background-color: #f6f6f6;
  }
}

body .ui-menu {
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(40, 40, 40, 0.1);
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 0;
  margin: 0;
  list-style: none;
}

body .ui-menu.ui-autocomplete .ui-menu-item-wrapper {
  padding: 0;
}

body .ui-menu .ui-menu-item {
  border-bottom: 1px solid rgba(151, 151, 151, 0.4);
}

body .ui-menu .ui-menu-item:last-child {
  border-bottom: none;
}

body .ui-widget.ui-widget-content {
  color: #282828;
  border: none;
}

body .ui-state-active,
body .ui-widget-content .ui-state-active,
body .ui-widget-header .ui-state-active,
body a.ui-button:active,
body .ui-button:active,
body .ui-button.ui-state-active:hover {
  background: #0076ff;
}

.go-search-autocomplete-item {
  padding: 10px;
  cursor: pointer;
}

.go-search-autocomplete-item:hover {
  background-color: #0076ff;
  color: #ffffff;
}

.go-search-autocomplete-item--idx {
  font-size: 1.2rem;
  opacity: .8;
  width: 20px;
  text-align: right;
}

.go-search-autocomplete-item--title {
  font-size: 1rem;
}

.go-search-autocomplete-item--intro {
  font-size: 0.75rem;
  opacity: .8;
}

.go-search-autocomplete-item--intro > b {
  opacity: 1;
}

.go-event {
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  background-color: #ffffff;
  border: 1px solid rgba(151, 151, 151, 0.2);
  transition: .3s ease;
}

.go-event:hover {
  box-shadow: 0 2px 10px rgba(40, 40, 40, 0.1);
}

.go-event.uk-open {
  box-shadow: 0 2px 10px rgba(40, 40, 40, 0.1);
}

.go-event.uk-open .go-event-content-open {
  transform: rotate(180deg);
}

.go-event-content-open {
  transition: transform 0.3s ease;
}

.go-event--title {
  line-height: 1.2;
  font-size: 1.2rem;
}

.go-event--meta {
  color: #9b9b9b;
  font-size: .9rem;
}

.go-event-toggle {
  cursor: pointer;
}

.go-event-actions {
  border-top: 1px solid rgba(151, 151, 151, 0.3);
  padding: 20px;
}

.go-event-content {
  padding: 20px;
  background-color: #f6f6f6;
}

.go-event-content > *:last-child {
  margin-bottom: 0;
}

.go-event-price > span {
  color: #0076ff;
  font-size: 1.2rem;
}

.go-event-spiker {
  background-color: #0076ff;
  color: #ffffff;
  padding: 20px;
}

.uk-input.error {
  border-color: rgba(204, 24, 10, 0.3);
  color: #cc180a;
}

.uk-input.error:focus {
  background-color: rgba(204, 24, 10, 0.1);
}

.go-org-item {
  overflow: hidden;
  position: relative;
}

.go-org-item-body {
  padding: 10px;
}

.go-org-item-title {
  font-weight: bold;
  margin-bottom: 5px;
  line-height: 1.2;
}

@media (max-width: 480px) {
  .go-org-item-title {
    font-size: .8rem;
  }
}

.go-org-item-meta {
  color: #9b9b9b;
  font-size: .75rem;
  line-height: 1.2;
}

@media (max-width: 480px) {
  .go-org-item-meta {
    font-size: .65rem;
  }
}

.time-visit-label {
  cursor: pointer;
}

.time-visit-label:hover .time-visit-text {
  color: #0076ff;
}

.time-visit-label > input:not(:disabled):not(:checked):hover {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxjaXJjbGUgZmlsbD0iIzAwMCIgY3g9IjgiIGN5PSI4IiByPSIyIiAvPgo8L3N2Zz4=");
}

.time-visit-label > input:disabled + .time-visit-text {
  color: rgba(204, 24, 10, 0.5);
}

.time-visit-label > input:checked + .time-visit-text {
  color: #0076ff;
}

.time-visit-text {
  color: #49b212;
}

.go-minicart {
  position: fixed;
  z-index: 10;
  bottom: 0;
  left: 0;
  right: 0;
}

.go-minicart-total {
  padding: 10px 0;
  background-color: #0076ff;
  color: #ffffff;
}

.go-minicart-result {
  height: 0;
  padding: 0;
  overflow: hidden;
  text-align: center;
  transition: .3s ease;
  background-color: #282828;
}

.go-minicart:hover .go-minicart-result {
  height: auto;
  padding: 10px 0;
}

.go-realty-options .uk-grid + .uk-grid {
  margin-top: 5px;
}

.go-realty-options .uk-grid + .uk-grid .uk-leader {
  color: #9b9b9b;
}

.go-realty-options .uk-grid {
  margin-top: 5px;
}

.go-realty-options .uk-grid .uk-leader {
  color: #9b9b9b;
}

.go-realty-map-button {
  transform: translateY(-50%);
  padding: 0;
  width: 40px;
  border-radius: 100%;
}

.map-expander {
  height: 1px;
  z-index: 420;
}

.go-realty-list-item {
  position: relative;
  transition: .3s ease;
  border-bottom: 1px solid rgba(151, 151, 151, 0.4);
}

.go-realty-list-item:hover {
  background-color: rgba(246, 246, 246, 0.6);
}

.go-realty-list-item:last-child {
  border-bottom: none;
}

@media (max-width: 768px) {
  .go-realty-list-item {
    font-size: 14px;
  }

  .go-realty-list-item .uk-h4 {
    font-size: 1rem;
  }

  .go-realty-list-item .uk-grid + .uk-grid,
  .go-realty-list-item .uk-grid > .uk-grid-margin,
  .go-realty-list-item * + .uk-grid-margin {
    margin-top: 10px;
  }

  .go-realty-list-item .uk-grid {
    margin-left: -10px;
  }

  .go-realty-list-item .uk-grid > * {
    padding-left: 10px;
  }
}

.go-realty-list-item--kv {
  font-size: 4rem;
}

.go-realty-list-item--price {
  width: 145px;
}

@media (max-width: 768px) {
  .go-realty-list-item--price {
    text-align: right;
    padding-right: 5px;
  }

  .go-realty-list-item--price .uk-h3 {
    font-size: 1.25rem;
  }
}

.go-realty-list-item--area {
  width: 185px;
}

.go-realty-header {
  padding: 0 40px;
  background-color: rgba(40, 40, 40, 0.3);
}

@media (max-width: 768px) {
  .go-realty-header {
    padding: 0 20px;
  }
}

@media (max-width: 768px) {
  .go-realty-header {
    padding: 0 10px;
  }
}

.go-realty-header-title {
  color: #ffffff;
  margin: 0 0 10px 0;
}

@media (max-width: 480px) {
  .go-realty-header-title {
    font-size: 1.2rem;
  }
}

.go-realty-header-cont {
  width: 100%;
  max-width: 1200px;
}

.go-realty-header-search {
  background-color: #ffffff;
  height: 100px;
  border-radius: 5px;
  position: relative;
  z-index: 1;
  box-shadow: 0 2px 10px rgba(40, 40, 40, 0.1);
}

@media (max-width: 960px) {
  .go-realty-header-search {
    height: 150px;
  }
}

@media (max-width: 768px) {
  .go-realty-header-search {
    font-size: .876rem;
  }
}

@media (max-width: 480px) {
  .go-realty-header-search {
    font-size: .7rem;
    height: 90px;
    border-radius: 0;
  }
}

.go-realty-header-search .uk-icon {
  line-height: 30px;
  margin-top: 5px;
  transform: scale(0.7) translateY(-5px);
}

.go-realty-switcher > li > .uk-grid > *:last-child .go-realty-filter-item {
  border-right: none;
}

.go-realty-search-options {
  position: absolute;
  z-index: 100;
  top: 100px;
  left: 0;
  display: none;
  padding: 5px 15px;
  background: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  list-style: none;
  max-width: 250px;
  margin: 0;
}

@media (max-width: 480px) {
  .go-realty-search-options {
    top: 70px;
    max-width: none;
  }
}

.go-realty-search-options > li > a {
  display: block;
  padding: 5px;
}

.go-realty-header-category::before {
  left: 0;
}

.go-realty-header-category > * {
  padding-left: 0 !important;
}

.go-realty-header-category > * > a {
  height: 50px;
  box-sizing: border-box;
  color: #282828;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 32px;
}

@media (max-width: 480px) {
  .go-realty-header-category > * > a {
    text-transform: none;
    height: 30px;
    padding: 0 5px;
  }
}

.go-realty-header-category > * > a:hover,
.go-realty-header-category > * > a:focus {
  color: #0076ff;
}

.go-realty-header-category > *.uk-active > a {
  color: #0076ff;
  border-bottom: 5px solid #0076ff;
}

.go-realty-filter-item {
  padding: 0 20px;
  display: inline-block;
  box-sizing: border-box;
  height: 50px;
  line-height: 50px;
  color: #282828;
  border: none;
  border-radius: 0;
}

@media (max-width: 480px) {
  .go-realty-filter-item {
    height: 30px;
    line-height: 30px;
    padding: 0 5px;
    font-size: .7rem;
    border: none;
  }
}

.go-realty-filter-item.no-border-right {
  border-right: none;
}

.go-realty-filter-item.no-padding-left {
  padding-left: 0;
}

.go-realty-filter-item:hover:not(.no-hover),
.go-realty-filter-item:focus:not(.no-hover) {
  color: #0076ff;
  background-color: rgba(0, 118, 255, 0.06);
}

.go-realty-filter-item > * {
  color: #282828;
}

.go-realty-filter-item > *:hover,
.go-realty-filter-item > *:focus {
  color: #0076ff;
}

@media (max-width: 960px) {
  .go-realty-filter-item--border-top {
    box-sizing: border-box;
    border-top: 1px solid rgba(151, 151, 151, 0.4);
  }
}

.go-realty-filter-input {
  width: 110px;
  padding-right: 10px;
}

.go-realty-filter-input-small {
  width: 90px;
  padding-right: 10px;
}

.go-realty-filter-item--icon {
  float: right;
  margin-top: 15px;
}

@media (max-width: 480px) {
  .go-realty-filter-item--icon {
    line-height: 30px;
    margin-top: 5px;
    width: 15px;
    height: 15px;
    transform: scale(0.7);
  }
}

.go-realty-summary-card .uk-list > li:nth-child(n+2),
.go-realty-summary-card .uk-list > li > ul {
  margin-top: 5px;
}

.go-realty-map-container {
  position: relative;
  height: calc(100vh - 222px);
  min-height: 50px;
  overflow: hidden;
}

.go-realty-map-container .go-realty-header-cont {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
}

.go-realty-map-view-item {
  position: absolute;
  z-index: 3;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #ffffff;
  box-shadow: 0 2px 10px rgba(40, 40, 40, 0.1);
  overflow: hidden;
}

.go-realty-map-view-item-header {
  padding: 20px 70px 20px 20px;
  flex: none;
  box-shadow: 0 2px 10px rgba(40, 40, 40, 0.1);
}

.go-realty-map-view-item-content {
  max-height: 350px;
  padding: 20px;
}

.poll-answer-item {
  text-align: center;
  cursor: pointer;
}

.poll-answer-item .uk-radio:checked ~ .poll-answer-item--smile .poll-answer-item--smile-image {
  transform: scale(1);
  opacity: 1;
}

.poll-answer-item:hover .poll-answer-item--smile-image {
  transform: scale(0.85);
  opacity: .8;
}

.poll-answer-item:hover .poll-answer-item--name {
  transform: translateY(-10px);
}

.poll-answer-item--smile-image {
  max-width: 100%;
  background: 50% 50% no-repeat;
  background-size: 100% 100%;
  transition: 1s ease;
  transform: scale(0.7);
  margin: 0 auto;
  opacity: .6;
}

.poll-answer-item--name {
  margin: 20px 0 0 0;
  font-weight: bold;
  transition: 1s ease;
}

@media (max-width: 768px) {
  .poll-answer-item--name {
    font-size: .8rem;
  }
}

@media (max-width: 480px) {
  .poll-answer-item--name {
    font-size: .4rem;
    text-transform: uppercase;
    font-weight: normal;
  }
}

#poll-answer-1:checked ~ .poll-answer-item--name {
  color: #49b212;
}

#poll-answer-2:checked ~ .poll-answer-item--name {
  color: #ffc10e;
}

#poll-answer-3:checked ~ .poll-answer-item--name {
  color: #cc180a;
}

.leaflet-popup-content {
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
}