//
//
//

@import 'variables';

// transition
@mixin transition($parameter) {
  transition: $parameter .3s ease; }
@mixin transitionTime($duration) {
  transition: $duration ease; }
@mixin transitionAll() {
  transition: .3s ease; }

// shadow
@mixin box-shadow($size) {
  box-shadow: 0 2px $size rgba($color-text, .1); }

// $border-rounded
@mixin border-rounded() {
  border-radius: $border-rounded; }
