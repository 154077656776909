@import "variables";
@import "mixin";

//
// Search
//
body {
  .ui-menu {
    background-color: $color-contrast;
    @include border-rounded;
    @include box-shadow(10px);
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    padding: 0;
    margin: 0;
    list-style: none;
    &.ui-autocomplete {
      .ui-menu-item-wrapper {
        padding: 0; } }
    .ui-menu-item {
      border-bottom: 1px solid $color-border;
      &:last-child {
        border-bottom: none; } } }

  .ui-widget {
    &.ui-widget-content {
      color: $color-text;
      border: none; } }

  .ui-state-active,
  .ui-widget-content .ui-state-active,
  .ui-widget-header .ui-state-active,
  a.ui-button:active,
  .ui-button:active,
  .ui-button.ui-state-active:hover {
    background: $color-primary; } }

// go-search-autocomplete-item
.go-search-autocomplete-item {
  padding: $global-small-margin;
  cursor: pointer;
  &:hover {
    background-color: $color-primary;
    color: $color-contrast; } }
.go-search-autocomplete-item--idx {
  font-size: 1.2rem;
  opacity: .8;
  width: 20px;
  text-align: right; }
.go-search-autocomplete-item--title {
  font-size: 1rem; }
.go-search-autocomplete-item--intro {
  font-size: $global-small-font-size;
  opacity: .8;
  & > b {
    opacity: 1; } }
