@import "variables";
@import "mixin";

//
// services-item
//
$service-item-height: 85px;
$service-item-height-small: 55px;

.go-service-item {
  display: block;
  height: $service-item-height;
  box-sizing: border-box;
  border-radius: $border-rounded;
  border: $border-width solid $color-primary;
  overflow: hidden;
  color: $color-text;
  @include transitionAll;
  @media (max-width: $breakpoint-medium) {
    height: $service-item-height-small;
    font-size: .875rem; }
  &.uk-active,
  &:hover {
    background-color: $color-primary;
    color: $color-contrast;
    text-decoration: none;
    transform: scale(1.02); }
  &.uk-active {
    transform: none; } }
.go-service-item--icon {
  width: 77px;
  height: $service-item-height - ($border-width * 2);
  background: $color-primary 50% 50% no-repeat;
  background-size: 45px 45px;
  text-align: center;
  padding-top: 7px;
  box-sizing: border-box;
  @media (max-width: $breakpoint-medium) {
    width: 40px;
    height: $service-item-height-small - ($border-width * 2);
    //transform: scale(.5) translate(-30px, -25%)
    padding: 0; }
  & > * {
    @media (max-width: $breakpoint-medium) {
      transform: scale(.5); } }
  &.no-icon {
    width: 20px; } }
.go-service-item--title {
  padding: 0 20px;
  box-sizing: border-box;
  line-height: 18px;
  font-size: 14px;
  @media (max-width: $breakpoint-medium) {
    font-size: 12px;
    line-height: 1.2;
    padding: 0 10px; } }
