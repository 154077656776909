@import 'variables';
@import "mixin";

.poll-answer-item {
  text-align: center;
  cursor: pointer;

  .uk-radio {
    &:checked {
      & ~ .poll-answer-item--smile {
        .poll-answer-item--smile-image {
          transform: scale(1);
          opacity: 1; } } } }

  &:hover {
    .poll-answer-item--smile-image {
      transform: scale(.85);
      opacity: .8; }

    .poll-answer-item--name {
      transform: translateY(-10px); } } }


.poll-answer-item--smile-image {
  max-width: 100%;
  background: 50% 50% no-repeat;
  background-size: 100% 100%;
  @include transitionTime(1s);
  transform: scale(.7);
  margin: 0 auto;
  opacity: .6; }

.poll-answer-item--name {
  margin: $global-margin 0 0 0;
  //font-size: 1.2rem
  font-weight: bold;
  @include transitionTime(1s);
  @media (max-width: $breakpoint-medium) {
    font-size: .8rem; }
  @media (max-width: $breakpoint-small) {
    font-size: .4rem;
    text-transform: uppercase;
    font-weight: normal; } }

#poll-answer-1 {
  &:checked {
    & ~ .poll-answer-item--name {
      color: $color-success; } } }

#poll-answer-2 {
  &:checked {
    & ~ .poll-answer-item--name {
      color: #ffc10e; } } }

#poll-answer-3 {
  &:checked {
    & ~ .poll-answer-item--name {
      color: $color-danger; } } }
