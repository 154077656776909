@import "variables";
@import "mixin";

//
// Org
//

.go-org-item {
  overflow: hidden;
  position: relative; }
.go-org-item-body {
  padding: $global-small-margin; }
.go-org-item-title {
  font-weight: bold;
  margin-bottom: 5px;
  line-height: 1.2;
  @media (max-width: $breakpoint-small) {
    font-size: .8rem; } }
.go-org-item-meta {
  color: $color-muted;
  font-size: .75rem;
  line-height: 1.2;
  @media (max-width: $breakpoint-small) {
    font-size: .65rem; } }
