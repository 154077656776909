@import "variables";
@import "mixin";

//
// Content - main style
//

.go-content {
  border-top: $border-width solid $color-border; }
// go-content-main
.go-content-main {
  padding: 35px 20px;
  @media (max-width: $breakpoint-medium) {
    padding: 20px 10px; } }

// go-content-side
.go-content-side {
  border-left: $border-width solid $color-border;
  text-align: center;
  padding: 35px 20px;
  width: 288px;
  height: 100%;
  box-sizing: border-box;
  @media (max-width: $breakpoint-medium) {
    padding: 20px 10px; }
  @media (max-width: $breakpoint-large) {
    width: auto; } }

// go-content-side-nav
.go-content-side-nav {
  position: relative;
  display: block;
  height: 120px;
  border: $border-width solid $color-primary;
  border-radius: $border-rounded;
  @media (max-width: $breakpoint-medium) {
    height: 90px; }
  @media (max-width: $breakpoint-small) {
    height: auto; }
  &:hover {
    border-color: $color-primary-hover;
    .go-content-side-nav--title {
      color: $color-primary;
      text-decoration: none; }
    & > .go-content-side-nav--icon {
      transform: scale(0.96);
      @media (max-width: $breakpoint-medium) {
        transform: scale(.85); } } } }
.go-content-side-nav--icon {
  width: 65px;
  height: 50px;
  margin: 10px auto 0 auto;
  background: 50% 50% no-repeat;
  background-size: 45px 35px;
  @include transition(transform);
  @media (max-width: $breakpoint-medium) {
    transform: scale(.8);
    margin: 0 auto; }
  @media (max-width: $breakpoint-small) {
    display: none; } }
.go-content-side-nav--title {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  color: $color-text;
  font-size: 1rem;
  padding: 0 10px;
  line-height: 1.3;
  height: 50px;
  @media (max-width: $breakpoint-medium) {
    font-size: .875rem;
    line-height: 1;
    height: auto; }
  @media (max-width: $breakpoint-small) {
    position: static;
    padding: 10px; } }

// go-content-side-e-reception
.go-content-side-e-reception {
  height: 155px;
  box-sizing: border-box;
  padding: 12px 0;
  display: block;
  background-color: $color-primary;
  border-radius: $border-rounded;
  @include transition(background-color);
  @media (max-width: $breakpoint-large) {
    height: 90px; }
  & > .go-content-side-nav--icon {
    height: 130px;
    width: 150px;
    margin: 0;
    display: inline-block;
    background-size: 100% auto;
    @media (max-width: $breakpoint-large) {
      transform: scale(.5) translateY(-50px); } }
  &:hover {
    background-color: $color-primary-hover;
    & > .go-content-side-nav--icon {
      @media (max-width: $breakpoint-large) {
        transform: scale(.51) translateY(-50px); } } } }
