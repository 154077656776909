@import "variables";
@import "mixin";

//
// Content - main style
//

.go-partner {
  text-align: center;
  position: relative; }
.go-partner--image {
  height: 100px;
  vertical-align: center;
  & > img {
    filter: grayscale(1);
    opacity: .3;
    @include transitionTime(.3s); } }
.go-partner--caption {
  color: $color-muted;
  padding-top: $global-margin;
  max-width: 180px;
  margin: 0 auto;
  @include transitionTime(.5s); }

//  hover

.go-partner {
  &:hover {
    .go-partner--image {
      & > img {
        filter: grayscale(0);
        opacity: 1; } }
    .go-partner--caption {
      color: $color-text;
      text-decoration: none;
      transform: translateY(-10px); } } }
