//
// FORMS
//

@import 'variables';
@import "mixin";


// Запись на прием

.time-visit-label {
  cursor: pointer;

  &:hover {
    .time-visit-text {
      color: $color-primary; } }

  & > input {
    &:not(:disabled) {
      &:not(:checked) {
        &:hover {
          background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxjaXJjbGUgZmlsbD0iIzAwMCIgY3g9IjgiIGN5PSI4IiByPSIyIiAvPgo8L3N2Zz4="); } } }

    &:disabled {
      & + .time-visit-text {
        color: rgba($color-danger, .5); } }

    &:checked {
      & + .time-visit-text {
        color: $color-primary; } } } }

.time-visit-text {
  color: $color-success; }

//
