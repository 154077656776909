//
// Files icons
//

@import 'variables';
@import "mixin";

.go-files {
  margin-top: $global-large-margin;
  border-radius: $border-rounded; }
//@include box-shadow(20px)

.go-files-title {
  background-color: $color-bgr-muted;
  padding: $global-margin;
  font-size: 1.2rem;
  font-weight: bold; }

.go-files-items {}
//padding: $global-margin

.go-file {}

.go-file-item {
  box-sizing: border-box;
  color: $color-primary;

  &:hover {
    text-decoration: none;
    color: $color-primary-hover;
    @include box-shadow(20px);

    .go-file-item__download {
      visibility: visible; }

 } }    //.go-file-item__icon
//background-size: 54px 54px

.go-file-item__download {
  visibility: hidden;
  padding: 0 $global-small-margin;
  color: $color-danger;
  animation: download 1s linear infinite; }

.go-file-item__title {
  display: block;
  line-height: 1.3;
  box-sizing: border-box;
  padding: $global-small-margin 0; }

// Files type images
.go-file-item__icon {
  padding: $global-small-margin 0 $global-small-margin $global-small-margin;
  width: 40px;
  height: 40px;
  box-sizing: content-box;
  background: 50% 50% no-repeat;
  display: block;
  background-size: 40px 40px; }
//@include transition(background-size)

.go-pdf {
  background-image: url(../images/icons/files-icon/pdf.svg); }

.go-doc,
.go-docx {
  background-image: url(../images/icons/files-icon/doc.svg); }

.go-xls,
.go-xlsx {
  background-image: url(../images/icons/files-icon/xls.svg); }

// download animations
@keyframes download {
  0% {
    opacity: 0;
    transform: translateY(-10px); }
  50% {
    opacity: 1;
    transform: translateY(0); }
  //20%
  //  transform: translateY(-2px)
  //  opacity: 1
  //40%
  //  transform: translateY(-1px)
  //  opacity: 1
  //60%
  //  transform: translateY(1px)
  //  opacity: 1
  //80%
  //  transform: translateY(2px)
  //  opacity: 1
  100% {
    transform: translateY(10px);
    opacity: 0; } }

