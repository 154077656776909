//
// CART
//

@import 'variables';
@import "mixin";

.go-minicart {
  position: fixed;
  z-index: 10;
  bottom: 0;
  left: 0;
  right: 0; }
.go-minicart-total {
  padding: $global-small-margin 0;
  background-color: $color-primary;
  color: $color-contrast; }
.go-minicart-result {
  height: 0;
  padding: 0;
  overflow: hidden;
  text-align: center;
  @include transitionAll;
  background-color: $color-text; }
.go-minicart {
  &:hover {
    .go-minicart-result {
      height: auto;
      padding: $global-small-margin 0; } } }
