@import "variables";
@import "mixin";

//
// Content - children style
//
.go-content-childrens {
  margin-bottom: $global-margin; }
.go-content-childrens > .uk-active > .go-content-children,
.go-content-children:hover {
  background-color: $color-primary;
  border: $border-width solid $color-border;
  color: $color-contrast; }
.go-content-children {
  border-radius: $border-rounded;
  position: relative;
  padding: $global-margin;
  background-color: $color-contrast;
  border: $border-width solid $color-primary;
  color: $color-primary;
  height: 100%;
  box-sizing: border-box; }
.go-content-children--title {
  font-size: 1.2rem;
  margin-bottom: 0; }
