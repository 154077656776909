@import "variables";
@import "mixin";

//
// Content - children style
//

.go-contacts {
  .go-contacts--row {
    margin-top: 5px; } }
.go-contacts--map {
  height: 600px;
  @media (max-width: 768px) {
    height: 400px; } }


.media-tab {
  @media (max-width: 768px) {
    overflow: auto;
    max-height: 200px;
    background-color: #f6f6f6; } }
