//
// Photo gallery
//

@import 'variables';
@import "mixin";

.go-gallery {
  margin-top: $global-medium-margin; }
.go-gallery-item {
  position: relative;
  overflow: hidden;
  &:hover {
    .go-gallery-item--image {
      transform: scale(1.1); }
    .go-gallery-item--overlay {
      background-color: rgba($color-contrast, .4);
      & > a {
        color: $color-text; } } } }
.go-gallery-item--image {
  @include transitionTime(1s); }
.go-gallery-item--overlay {
  z-index: 1;
  @include transitionAll;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba($color-contrast, 0);
  & > a {
    color: $color-contrast; } }
