@import "variables";
@import "mixin";

//
// Realty
//

.go-realty-options {
  .uk-grid {
    & + .uk-grid {
      margin-top: 5px;

      .uk-leader {
        color: $color-muted; } } } }

.go-realty-options {
  .uk-grid {
    margin-top: 5px;

    .uk-leader {
      color: $color-muted; } } }

.go-realty-map-button {
  transform: translateY(-50%);
  padding: 0;
  width: 40px;
  border-radius: 100%; }

.map-expander {
  height: 1px;
  z-index: 420; }

//
.go-realty-list-item {
  position: relative;
  @include transitionAll;
  border-bottom: 1px solid $color-border;

  &:hover {
    background-color: rgba($color-bgr-muted, .6); }

  &:last-child {
    border-bottom: none; }

  @media (max-width: $breakpoint-medium) {
    font-size: 14px;
    .uk-h4 {
      font-size: 1rem; }
    .uk-grid + .uk-grid,
    .uk-grid > .uk-grid-margin,
    * + .uk-grid-margin {
      margin-top: $global-small-margin; }

    .uk-grid {
      margin-left: -$global-small-margin;

      & > * {
        padding-left: $global-small-margin; } } } }

.go-realty-list-item--kv {
  font-size: 4rem; }

.go-realty-list-item--price {
  width: 145px;
  @media (max-width: $breakpoint-medium) {
    text-align: right;
    padding-right: 5px;
    .uk-h3 {
      font-size: 1.25rem; } } }

.go-realty-list-item--area {
  width: 185px; }

$go-realty-header-height: 100px;
$go-realty-header-height-tab: 50px;
$go-realty-header-height-small-tab: 30px;
$go-realty-header-height-filter: $go-realty-header-height - $go-realty-header-height-tab;

// header
.go-realty-header {
  padding: 0 $global-medium-margin;
  background-color: rgba($color-text, .3);
  @media (max-width: $breakpoint-medium) {
    padding: 0 $global-margin; }
  @media (max-width: $breakpoint-medium) {
    padding: 0 $global-small-margin; } }

.go-realty-header-title {
  color: $color-contrast;
  margin: 0 0 $global-small-margin 0;
  @media (max-width: $breakpoint-small) {
    font-size: 1.2rem; } }

.go-realty-header-cont {
  width: 100%;
  max-width: 1200px; }

.go-realty-header-search {
  background-color: $color-contrast;
  height: $go-realty-header-height;
  border-radius: $border-rounded;
  position: relative;
  z-index: 1;
  @include box-shadow(10px);

  @media(max-width: $breakpoint-large) {
    height: $go-realty-header-height + 50; }

  @media (max-width: $breakpoint-medium) {
    font-size: .876rem; }

  @media (max-width: $breakpoint-small) {
    font-size: .7rem;
    height: $go-realty-header-height-small-tab * 3;
    border-radius: 0;
    // .uk-grid
    //   & > .uk-width-expand
    //     order: -1
    //     width: auto
    //     flex: none
    //     white-space: nowrap
    //
    //     & > .uk-button
 }    //       min-width: 120px

  .uk-icon {
    line-height: $go-realty-header-height-small-tab;
    margin-top: 5px;
    // width: 15px
    // height: 15px
    transform: scale(.7) translateY(-5px); } }


.go-realty-switcher {
  & > li {
    & > .uk-grid {
      & > * {
        &:last-child {
          .go-realty-filter-item {
            border-right: none; } } } } } }

// ???
.go-realty-search-options {
  position: absolute;
  z-index: 100;
  //top: 70px
  top: 100px;
  left: 0;
  display: none;
  padding: 5px 15px;
  background: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0,0,0,.08);
  list-style: none;
  max-width: 250px;
  margin: 0;

  @media (max-width: $breakpoint-small) {
    top: 70px;
    max-width: none; }

  & > li > a {
    display: block;
    padding: 5px; } }

.go-realty-header-category {

  &::before {
    left: 0; }

  & > * {
    padding-left: 0 !important;

    & > a {
      height: $go-realty-header-height-tab;
      box-sizing: border-box;
      color: $color-text;
      text-transform: uppercase;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: $go-realty-header-height-tab - 18;
      @media (max-width: $breakpoint-small) {
        text-transform: none;
        height: $go-realty-header-height-small-tab;
        padding: 0 5px; }

      &:hover,
      &:focus {
        color: $color-primary; } }

    &.uk-active {
      & > a {
        color: $color-primary;
        border-bottom: 5px solid $color-primary; } } } }

// go-realty-filter
.go-realty-filter-item {
  padding: 0 $global-margin;
  display: inline-block;
  box-sizing: border-box;
  height: $go-realty-header-height-filter;
  line-height: $go-realty-header-height-filter;
  color: $color-text;
  border: none;
  // border-right: 1px solid $color-border
  border-radius: 0;
  @media (max-width: $breakpoint-small) {
    height: $go-realty-header-height-small-tab;
    line-height: $go-realty-header-height-small-tab;
    padding: 0 5px;
    font-size: .7rem;
    border: none; }

  &.no-border-right {
    border-right: none; }

  &.no-padding-left {
    padding-left: 0; }

  &:hover:not(.no-hover),
  &:focus:not(.no-hover) {
    color: $color-primary;
    background-color: rgba($color-primary, .06); }

  & > * {
    color: $color-text;

    &:hover,
    &:focus {
      color: $color-primary; } } }

.go-realty-filter-item--border-top {
  @media(max-width: $breakpoint-large) {
    box-sizing: border-box;
    border-top: 1px solid $color-border; } }

.go-realty-filter-input {
  width: 110px;
  padding-right: 10px; }


.go-realty-filter-input-small {
  width: 90px;
  padding-right: 10px; }

.go-realty-filter-item--icon {
  float: right;
  margin-top: 15px;
  @media (max-width: $breakpoint-small) {
    line-height: $go-realty-header-height-small-tab;
    margin-top: 5px;
    width: 15px;
    height: 15px;
    transform: scale(.7); } }

// go-realty-summary-card
.go-realty-summary-card {
  .uk-list > li:nth-child(n+2),
  .uk-list > li > ul {
    margin-top: 5px; } }

// go-realty-map-container
.go-realty-map-container {
  position: relative;
  height: calc(100vh - 222px);
  min-height: 50px;
  overflow: hidden;

  .go-realty-header-cont {
    position: absolute;
    top: $global-margin;
    left: $global-margin;
    right: $global-margin; } }

// go-realty-map-view-item
.go-realty-map-view-item {
  position: absolute;
  z-index: 3;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: $color-contrast;
  @include box-shadow(10px);
  overflow: hidden; }

.go-realty-map-view-item-header {
  padding: $global-margin $global-large-margin $global-margin $global-margin;
  flex: none;
  @include box-shadow(10px); }

.go-realty-map-view-item-content {
  max-height: 350px;
  padding: $global-margin; }
