@import "variables";
@import "mixin";

//
// News
//

// news mini card
.go-news-list-item {
  display: block;
  color: $color-text;
  padding: $global-margin;
  border-radius: $border-rounded;
  @include transitionAll;
  &:hover {
    background-color: $color-contrast;
    text-decoration: none;
    color: $color-text;
    @include box-shadow(19px);
    transform: translateY(-2px);
    .go-news-list-item--title {
      color: $color-primary; } } }
.go-news-list-item--title {
  font-weight: 700;
  margin-bottom: 8px; }
.go-news-list-item--meta {
  font-size: 12px;
  color: $color-muted;
  font-style: italic; }
.go-news-list-item--image {
  margin-top: 5px;
  width: 107px;
  background: 50% 50% no-repeat;
  background-size: cover;
  border-radius: $border-rounded; }

// .go-news-filter
.go-news-filter--link {
  color: $color-muted; }
.go-news-filter {
  & > li {
    &:nth-child(1) {
      padding-left: 0; } }
  input:checked {
    & + .go-news-filter--link {
      color: $color-primary; } }
  .go-news-filter--link {
    &:hover,
    &:focus {
      color: $color-text;
      text-decoration: none;
      outline: 0; } } }
