@import "styles/variables";
@import "styles/mixin";
@import "styles/base";
@import "styles/header";
@import "styles/news";
@import "styles/footer";
@import "styles/content";
@import "styles/subscribe";
@import "styles/service-item";
@import "styles/partners";
@import "styles/banners";
@import "styles/photo-gallery";
@import "styles/files-icon";
@import "styles/children";
@import "styles/contacts";
@import "styles/search";
@import "styles/events";
@import "styles/org";
@import "styles/forms";
@import "styles/cart";
@import "styles/realty";
@import "styles/polls";

@import "../node_modules/leaflet/dist/leaflet.css";

.leaflet-popup-content {
    max-height: 250px;
    overflow-y: auto;
    overflow-x: hidden; }
