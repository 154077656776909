@import "variables";
@import "mixin";

//
// Content - main style
//
.go-subscribe {
  input {
    border-radius: $border-rounded 0 0 $border-rounded; }
  button {
    border-radius: 0 $border-rounded $border-rounded 0;
    padding: 0;
    width: 40px; } }
