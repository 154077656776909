@import "variables";
@import "mixin";

//
// Footer
//

.go-footer {}

.go-footer-banners {
  height: 225px;
  background-color: $color-bgr-muted;
  border-top: $border-width solid $color-border;
  border-bottom: $border-width solid $color-border; }

.go-footer-subscribe {
  padding: $global-margin;
  border-top: $border-width solid $color-border;

  & > * {
    &:first-child {
      max-width: 400px;
      margin: 0 auto; } } }

.go-footer-copyright {
  box-sizing: border-box;
  padding: 0 $container-padding;
  height: $global-large-margin;
  line-height: $global-large-margin;
  text-align: center;
  @media (max-width: $breakpoint-medium) {
    height: $global-medium-margin;
    line-height: $global-medium-margin; } }
